<template>
  <div class="home pa-6">
    <v-flex row class="pb-2">
      <v-icon :color="theme.color">mdi-folder</v-icon>
      <v-subheader class="text-h6">
        <h4>DOKUMEN</h4>
      </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-7">
      <div class="grey--text mb-2">Seluruh Dokumen Yang Tersedia</div>
    </v-flex>

    <v-row>
      <v-col col="12">
        <v-card>
          <v-card-title
            :class="`flex flex-row-reverse ` + theme.color + ` lighten-1`"
          >
            <v-tooltip :color="theme.color + ` draken-4`" bottom>
              <template v-slot:activator="{ on }">
                <v-btn text small icon v-on="on">
                  <v-icon color="white" @click="addNewRecord"
                    >add_circle</v-icon
                  >
                </v-btn>
              </template>
              <span>Tambah Dokumen </span>
            </v-tooltip>

            <v-tooltip :color="theme.color + ` draken-4`" bottom>
              <template v-slot:activator="{ on }">
                <v-btn text small icon v-on="on">
                  <v-icon color="white" @click="fetchRecords">refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.download="{ value }">
              <v-tooltip color="green" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="green" @click="postDownload(value)"
                      >cloud_download</v-icon
                    >
                  </v-btn>
                </template>

                <span>Hapus Data</span>
              </v-tooltip>
            </template>
            <template v-slot:item.status="{ value }">
              <v-chip small :color="value ? `green` : `red`">{{
                value ? "Wajib" : "Opsional"
              }}</v-chip>
            </template>
            <template v-slot:item.id="{ value }">
              <v-tooltip color="green" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="green" @click="openDisposisi(value)"
                      >send</v-icon
                    >
                  </v-btn>
                </template>

                <span>Disposisi</span>
              </v-tooltip>
              <v-tooltip color="purple" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="purple" @click="postDeleteRecord(value)"
                      >personal_video</v-icon
                    >
                  </v-btn>
                </template>

                <span>Partinjau Dokumen</span>
              </v-tooltip>
              <v-tooltip color="blue" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="blue" @click="postDeleteRecord(value)"
                      >print</v-icon
                    >
                  </v-btn>
                </template>

                <span>Cetak Dokumen</span>
              </v-tooltip>
              <v-tooltip color="orange" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="orange" @click="postDeleteRecord(value)"
                      >edit</v-icon
                    >
                  </v-btn>
                </template>

                <span>Edit Data</span>
              </v-tooltip>

              <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text small icon v-on="on">
                    <v-icon color="red" @click="postDeleteRecord(value)"
                      >delete</v-icon
                    >
                  </v-btn>
                </template>

                <span>Hapus Data</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="add"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar :color="theme.color" dark>Formulir Dokumen </v-toolbar>
          <v-card-title class="justify-center">DATA DOKUMEN</v-card-title>
          <v-card-text>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Nomor Surat"
                v-model="record.nomor"
                dense
              ></v-text-field>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Tanggal Surat"
                v-model="record.tanggal"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Perihal"
                v-model="record.perihal"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Ulasan Surat/Dokumen"
                :color="theme.color"
                dense
                outlined
                hide-details
                v-model="record.ulasan"
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="File PDF (Max: 2 MB)"
                append-outer-icon="attachment"
                v-model="path"
                :color="theme.color"
                @click:append-outer="uploadFile"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Surat Ini Di Tandatangani Oleh :"
                :color="theme.color"
                dense
                outlined
                v-model="record.pejabat_id"
                :items="pejabats"
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Halaman Yang Akan Ditandatangan"
                v-model="record.pos_hal"
                :color="theme.color"
                outlined
                dense
                hide-details
                type="number"
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!edit"
              @click="postAddNewRecord"
              >Tambah</v-btn
            >
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postUpdateRecord"
              >Ubah</v-btn
            >
            <v-btn outlined color="grey" @click="add = false">Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",

  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num" },
      { text: "NOMOR SURAT", value: "nomor" },
      { text: "TANGGAL", value: "tanggal" },
      { text: "PERIHAL", value: "perihal" },
      { text: "STATUS", value: "status" },
      { text: "OPSI", value: "id" },
    ],

    search: null,
    path: null,

    add: false,
    edit: false,

    pejabats: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/dokumen",
    });

    this.fetchRecords();
    this.fetchPejabats();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
    ]),
    addNewRecord: function () {
      this.setRecord({});
      this.add = true;
      this.edit = false;
    },
    postAddNewRecord: function () {
      this.record.formasi_apply_id = this.$route.params.formasi_apply_id;
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
      });
    },
    editRecord: function (val) {
      this.postEdit(val);
      this.add = true;
      this.edit = true;
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    fetchPejabats: async function () {
      let { data } = await this.http.post("api/master-data/pejabat-combo");

      this.pejabats = data;
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".pdf"],
        query: {
          doctype: "source-dokumen",
        },
        callback: (response) => {
          setTimeout(() => {
            this.path = response.name;
            this.record.path_source = response.name;
          }, 500);
        },
      });
    },

    openDisposisi: function (val) {
      this.$router.push({
        name: "operator-disposisi",
        params: { dokumen_id: val },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },
  },
};
</script>
