<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :color="theme.color + `  darken-1`"
      :src="info.app_nav"
      app
      dark
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ info.app_name }}
          </v-list-item-title>
          <v-list-item-subtitle>Ver. {{ info.app_ver }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img :src="user.avatar"></v-img>
        </v-list-item-avatar>
        <!--<v-list-item-title>{{ user.username }} </v-list-item-title>-->
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.username }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="(menu, index) in menus">
          <v-list-item
            :active-class="theme.mode + '--text'"
            :to="menu.route"
            :key="index"
            v-if="menu.type === 'item'"
          >
            <v-list-item-action>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-subheader
            :class="menu.class"
            :key="index"
            class="overline mt-3"
            style="height: 24px"
            v-else-if="menu.type === 'subheader'"
            >{{ menu.title }}</v-subheader
          >
        </template>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-btn
            :color="theme.color + ` darken-4 white--text`"
            block
            @click="signout"
          >
            Logout <v-icon>exit_to_app</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app :color="theme.color + ` draken-4`" dark>
      <!--
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :gradient="`to top ,` + theme + `, black `"
        ></v-img>
      </template>
      -->

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title v-show="device.desktop">{{
        info.app_company
      }}</v-toolbar-title>

      <v-toolbar-title v-show="device.mobile">{{
        info.app_name
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>notification_important</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!--  -->
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="snackbar.state"
      :color="snackbar.color"
      :timeout="3500"
      multi-line
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbarClose">
          Tutup
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog.delete" persistent max-width="300px">
      <v-card>
        <v-card-title class="headline red white--text" light
          ><v-icon color="white" class="pr-3">report_problem</v-icon>
          <span>Konfirmasi</span></v-card-title
        >
        <v-card-text>
          <p class="pt-5">Apakah anda yakin akan menghapus data tersebut...?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :color="theme.color" text @click="dialogDeleteClose"
            >Tidak</v-btn
          >
          <v-btn color="red" text @click="deleteRecord">Ya, Saya Yakin</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-dialog v-model="loading.dialog" persistent width="300">
        <v-card :color="theme.color" dark>
          <v-card-text class="pa-2">
            {{ loading.text }}
            <br />
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="upload.progress" persistent width="300">
        <v-card>
          <v-card-title
            :class="theme.color + ` darken-1 pa-1 white--text subtitle-1`"
            >UPLOAD PROGRESS</v-card-title
          >
          <br />

          <v-card-text class="pa-2">
            <v-progress-linear
              v-model="upload.percent"
              :color="theme.color + ` darken-1`"
              height="20"
              reactive
              dark
            >
            </v-progress-linear>
            <div class="text-center">{{ upload.percent }} %</div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="upload-wrapper">
      <div id="upload-button"></div>
    </div>
  </v-app>
</template>

<script>
import { baseMixins } from "../../mixings/BaseMixins";
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    drawer: null,
    vertical: true,
    bg: "",
  }),

  mixins: [baseMixins],

  computed: {
    ...mapState([
      "snackbar",
      "theme",
      "thememode",
      "dialog",
      "user",
      "menus",
      "page",
      "device",
      "loading",
      "upload",
      "info",
    ]),
  },
  created() {
    this.bg = this.page.domainUrl + "images/bg-nav-purple-3.jpg";
  },
  mounted() {
    this.getAppInfo().then(() => {
      window.document.title = this.info.app_name;
      const favicon = window.document.getElementById("favicon");
      favicon.href = this.info.apps_logo;
    });
    this.getUserInfo();
    this.getMenus();
  },
  methods: {
    ...mapActions([
      "signOut",
      "snackbarClose",
      "postDelete",
      "dialogDeleteClose",
      "fetchRecords",

      "getUserInfo",
      "getMenus",
      "clearMenu",
      "getAppInfo",
      "setLoading",
    ]),

    signout: function () {
      this.clearMenu();
      this.signOut();
      this.$router.push({ name: "home" });
    },
    deleteRecord: function () {
      this.postDelete().then(() => {
        this.fetchRecords();
        this.dialogDeleteClose();
      });
    },
  },
};
</script>
