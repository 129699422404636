import { mapActions, mapState } from "vuex";
import { domainURL } from "../.env";

export const baseMixins = {
  computed: {
    ...mapState(["upload"]),
  },

  methods: {
    ...mapActions(["initUploadLibrary"]),
  },

  mounted() {
    this.initUploadLibrary({
      //baseEndpoint: domainURL.BASE + "api/media",
      baseEndpoint: "https://openapi.tangerangkab.go.id/api/service/media",
    });
  },
};
