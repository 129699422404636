import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../providers/AuthProviders";
import Home from "../views/auth/Home.vue";
import Formasi from "../views/auth/Formasi.vue";
import Dokumen from "../views/auth/Dokumen.vue";
import Pagu from "../views/auth/Pagu.vue"

import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";

import Dashboard from "../views/backend/pages/Dashboard.vue";

import Base from "../views/backend/Base.vue";

import User from "../views/backend/pages/User.vue";
import Changepwd from "../views/backend/pages/user/Changepwd.vue";
import ProfilAkun from "../views/backend/pages/user/Profil.vue";

//page master data
import MasterAppInfo from "../views/backend/pages/masterdata/appinfo/Index.vue"
import MasterPejabat from "../views/backend/pages/masterdata/pejabat/Index.vue"

//page operator
import OperatorDokumen from "../views/backend/pages/operator/dokumen/Index.vue"
import OperatorDisposisi from "../views/backend/pages/operator/disposisi/Index.vue"


//Backend Halaman Depan
import HalamanDepanSlider from "../views/backend/pages/halamandepan/sliders/Index.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/pagu",
    name: "pagu",
    component: Pagu,
  },
  {
    path: "/formasi",
    name: "formasi",
    component: Formasi,
  },
  {
    path: "/dokumen",
    name: "dokumen",
    component: Dokumen,
  },
  {
    path: "/backend",
    name: "",
    meta: {
      auth: true,
    },
    component: Base,
    children: [
      { path: "", redirect: { name: "dashboard" } },
      { path: "dashboard", name: "dashboard", component: Dashboard },
      { path: "user", name: "user", component: User },
      {
        path: "chngpwd",
        name: "chngpwd",
        component: Changepwd,
      },
      {
        path: "profil-akun",
        name: "profil-akun",
        component: ProfilAkun,
      },
      //route master data
      {
        path: "master-app-info",
        name: "master-app-info",
        component: MasterAppInfo,
      },
      {
        path: 'master-pejabat',
        name: 'master-pejabat',
        component: MasterPejabat
      },

      /**
       * Route Operator
       */
      {
        path: "operator-dokumen",
        name: "operator-dokumen",
        component: OperatorDokumen,
      },
      {
        path: "operator-disposisi/:dokumen_id",
        name: "operator-disposisi",
        component: OperatorDisposisi,
      },

      /**
       * Route Manajemen Halaman Depan
       */

      //Route slider
      {
        path: "halaman-depan-slider",
        name: "halaman_depan_slider",
        component: HalamanDepanSlider
      }

    ],
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

/**
 * router match
 */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (requiresAuth) {
    if (!Auth.check) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && Auth.check) {
      if (Auth.user.authent == "peserta") {
        next({ name: "dashboard-peserta" });
      } else {
        next({ name: "dashboard" });
      }
    } else {
      next();
    }
  }
});

export default router;
