<template>
  <v-app
    id="inspire"
    light
    v-cloak
    v-scroll="onScroll"
  >
    <v-app-bar
      :elevation="lightToolbar ? 0 : 6"
      :class="lightToolbar ? `transparent` : theme.color + ` darken-1`"
      fixed
    >
      <v-app-bar-nav-icon>
        <img
          class="ml-10"
          :src="info.app_logo"
          alt="LOGO"
          height="48"
        /></v-app-bar-nav-icon>

      <v-toolbar-title
        :class="{ 'pl-1': device.mobile }"
        v-if="!device.mobile"
      >
        <span class="font-weight-bold pl-5 white--text">{{
          info.app_company
        }}</span><br />
        <span class="text-xs pl-5 white--text">{{ info.app_slogan }}</span>
      </v-toolbar-title>

      <v-toolbar-title
        :class="{ 'pl-2': device.mobile }"
        v-if="device.mobile"
      >
        <span class="font-weight-bold pl-5 white--text">{{
          info.app_company
        }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="pr-5">
        <v-btn
          text
          class="white--text"
          v-show="device.desktop"
        >BERANDA</v-btn>

        <!--<v-btn flat>Blog</v-btn>-->

        <v-btn
          text
          @click="$router.push({ name: 'login' })"
          class="white--text"
          v-show="device.desktop"
        >LOGIN</v-btn>
      </v-toolbar-items>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="device.mobile"
            color="white"
            icon
            light
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="$router.push({ name: 'home' })">
              Beranda
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push({ name: 'login' })">
              Login
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <section>
        <v-carousel
          cycle
          :height="device.desktop ? `500` : `400`"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(slide, i) in sliders"
            :key="i"
            :src="slide.path"
          >
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-layout
                column
                align-center
                justify-center
                class="white--text"
              >
                <h1
                  class="white--text mb-2 display-1 text-xs-center"
                  style="font-weight: 900; text-shadow: 3px 2px #000000"
                >
                  <!-- title -->
                </h1>
                <div
                  class="white--text subheading mb-3 text-xs-center"
                  style="font-weight: 900; text-shadow: 2px 2px #000000"
                >
                  <!-- title -->
                </div>

                <div
                  class="white--text subheading mb-3 text-xs-center"
                  style="font-weight: 900; text-shadow: 2px 2px #000000"
                >
                  <!-- title -->
                </div>
              </v-layout>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </section>
      <section class="">
        <v-layout
          column
          wrap
          class="my-5"
          align-center
        >
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout
                row
                wrap
                align-center
              >
                <v-flex
                  xs12
                  md6
                >
                  <v-card
                    :class="
                      `mx-auto elevation-10 ` + theme.color + ` lighten-1`
                    "
                    max-width="344"
                    outlined
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4 white--text">
                          JUMLAH APLIKASI
                        </div>
                        <v-list-item-title class="headline mb-1 white--text">
                          {{ jmllakilaki }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="white--text">Item</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="80"
                        class="elevation-2"
                        color="white"
                      >
                        <v-img
                          class="ma-10"
                          src="images/dokumen.png"
                        ></v-img>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions> </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex
                  xs12
                  md6
                >
                  <v-card
                    :class="
                      `mx-auto elevation-10 ` + theme.color + ` lighten-1`
                    "
                    max-width="344"
                    outlined
                  >
                    <v-list-item three-line>
                      <v-list-item-content>
                        <div class="overline mb-4 white--text">
                          JUMLAH DATABASE
                        </div>
                        <v-list-item-title class="headline mb-1 white--text">
                          {{ jmlperempuan }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="white--text">Dokumen</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-avatar
                        size="80"
                        class="elevation-2"
                        color="white"
                      >
                        <v-img src="images/dokumen-verify.png"></v-img>
                      </v-list-item-avatar>
                    </v-list-item>

                    <v-card-actions> </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
      <section :class="theme.color + ` lighten-5 mb-0`">
        <v-layout
          column
          wrap
          :class="`my-5 ` + theme.color + `  lighten-5 mb-0 mt-0`"
          align-center
        >
          <v-flex
            xs12
            sm4
            class="my-3"
          >
            <div class="text-xs-center text-center">
              <h2 class="headline">INFORMASI</h2>
              <span class="subheading">{{ pengumuman.title }} </span>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout
                row
                wrap
                align-center
              >
                <center>
                  {{ pengumuman.body }}
                </center>
                <br />
                <br />
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
    </v-main>
    <section :class="theme.color + ` lighten-5`">
      <v-layout
        column
        wrap
        :class="theme.color + ` darken-1 pb-0`"
      >
        <v-flex class="pb-0 my-2 white--text align-center">
          <div class="subtitle-1 text-center">
            {{ info.app_company }}<br />

            &copy; 2021
          </div>
        </v-flex>
      </v-layout>
    </section>

    <v-snackbar
      v-model="snackbar.state"
      :color="snackbar.color"
      :timeout="3500"
    >
      {{ snackbar.text }}
      <v-btn
        dark
        text
        @click.stop="snackbarClose"
      >Tutup</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    uname: "",
    upass: "",
    visible: false,
    color: "purple darken-3",
    jmlpendaftar: 0,
    jmllakilaki: 0,
    jmlperempuan: 0,

    pengumuman: {
      title: null,
      body: null,
    },
    sliders: [],
    lightToolbar: true,
  }),
  mounted() {
    this.setPage({
      crud: true,
    }).then(() => {
      this.getAppInfo().then(() => {
        window.document.title = this.info.app_company;
        const favicon = window.document.getElementById("favicon");
        favicon.href = this.info.apps_logo;
      });
    });
    this.fetchSliders();
    this.fetchDashboard();
    this.fetchSummarydata();
    this.fetchPengumuman();
  },
  computed: {
    ...mapState(["device", "info", "snackbar", "device", "http", "theme"]),

    contentStyle: function () {
      if (!this.device.mobile && this.info.apps_background) {
        return `background: url(${this.info.apps_background});
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;`;
      }
      return ``;
    },
  },
  methods: {
    ...mapActions([
      "setPage",
      "getAppInfo",
      "signIn",
      "snackbarClose",
      "getAppInfo",
    ]),
    onScroll: function (event) {
      if (window.pageYOffset >= window.innerHeight - 350) {
        if (this.lightToolbar === true) this.lightToolbar = false;
      } else {
        if (this.lightToolbar === false) this.lightToolbar = true;
      }
    },
    postAuthent: function () {
      this.signIn({
        username: this.uname.replace(/ /g, ""),
        userpass: this.upass,
      }).then((pass) => {
        if (!pass) {
          console.clear();
          return;
        }
        try {
          this.$router.push({ name: "dashboard" });
        } catch (error) {}
      });
    },
    postRegister: function () {
      this.$router.push({ name: "register" });
    },
    fetchDashboard: async function () {
      let {
        data: { jmlformasi, jmlpelamar },
      } = await this.http.get("service/dashboard-index");
      this.jmlformasi = jmlformasi;
      this.jmlpelamar = jmlpelamar;
    },
    fetchPengumuman: async function () {
      let { data } = await this.http.get("service/pengumuman-topnews");

      this.pengumuman = data;
    },
    fetchSliders: async function () {
      let { data } = await this.http.get("sliders");

      this.sliders = data;
    },
    fetchSummarydata: async function () {
      let {
        data: { jmlpendaftar, jmllakilaki, jmlperempuan },
      } = await this.http.get("summarydata");

      this.jmllakilaki = jmllakilaki;
      this.jmlperempuan = jmlperempuan;
      this.jmlpendaftar = jmlpendaftar;
    },
  },
};
</script>

<style lang="sass">
@import '../../sass/card'
@import '../../sass/moui.sass'
@import '../../sass/textField.sass'
</style>
