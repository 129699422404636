<template>
  <div class="apps">
    <v-fade-transition origin="center center">
      <router-view></router-view>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: "apps",
};
</script>

<style></style>
